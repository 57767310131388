// import { Icon } from '@iconify/react';
import { useRef, useState } from 'react'
// import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Box,
  Divider,
  Typography,
  Avatar,
  IconButton
} from '@mui/material'
// components
import MenuPopover from '../../components/MenuPopover'
//
import account from '../../_mocks_/account'

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: homeFill,
//     linkTo: '/',
//   },
//   {
//     label: 'Profile',
//     icon: personFill,
//     linkTo: '#',
//   },
//   {
//     label: 'Settings',
//     icon: settings2Fill,
//     linkTo: '#',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          backgroundColor: '#DD29FF',
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt='photoURL' />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220, backgroundColor: '#151517' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
            {localStorage.getItem('role')}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
              { localStorage.getItem('role') === "superadmin" ? (account.email) : "admin.gmail.com"}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={(e) => {
              localStorage.clear();
              navigate("/", { replace: true });
            }}
            sx={{
              // border: "2px solid transparent", // Transparent border for gradient
              // borderImage: "linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1", // Gradient border
              // borderRadius: "8px", // Optional: rounded corners
              color: "white", // Gradient color for text (optional)
              "&:hover": {
                background: "linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)", // Gradient background on hover
                color: "#fff", // White text on hover
              },
            }}
          >
            Logout
          </Button>

        </Box>
      </MenuPopover>
    </>
  )
}
