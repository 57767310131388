
import {
  Box,
  Grid,
  Container,
  Typography,
  TextField,
  Button,
  Skeleton,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useState } from 'react'
import Page from '../components/Page'
import { AppVerifiedUsers } from '../components/_dashboard/app'
import { services } from '../services'
import './dashboardStyle.css'
import TaskCompletionChart from 'src/components/charts/TaskCompletionChart'

export default function DashboardApp() {
  const [data, setData] = useState({
    totalUsers: 0,
    totalLifetimePoints: 0,
    activeUsers: 0,
    currentActiveUsers: 0,
    totalTaps: 0,
    totalTasksCompleted: 0,
    redeemedPoints: 0
  })

  const [taskCompletionCounts, setTaskCompletionCounts] = useState([])
  const [startDate1, setStartDate1] = useState(null)
  const [endDate1, setEndDate1] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('taskName')
  const [filterText, setFilterText] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [partnerStartDate, setPartnerStartDate] = useState(null)
  const [partnerEndDate, setPartnerEndDate] = useState(null)
  const [partnerFilterText, setPartnerFilterText] = useState('')


  const fetchData = async (start, end) => {
    setIsLoading(true)
    try {
      let userChartStatsUrl = `${process.env.REACT_APP_BASE_URL}/admin/dashboard-user-data`

      const userChartStatsResponse = await services.Get(userChartStatsUrl)

      if (userChartStatsResponse.status === 200) {
        const chartStatistics = userChartStatsResponse.data

        setData(chartStatistics)
      }

    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setIsLoading(false)
    }
  }
  // const fetchUserTaskData = async (start, end) => {
  //   setIsLoading(true)
  //   try {
  //     let taskCompletionUrl = `${process.env.REACT_APP_BASE_URL}/task/task-completion-counts`

  //     if (start && end) {
  //       taskCompletionUrl += `?startDate=${start}&endDate=${end}`
  //     }
  //     const taskCompletionResponse = await services.Get(taskCompletionUrl)
  //     if (taskCompletionResponse.status === 200) {
  //       setTaskCompletionCounts(taskCompletionResponse.data.data)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user task data:', error)
  //     setTaskCompletionCounts([]) // Set to empty array on error
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  const handleFilter = () => {
    if (startDate && endDate) {
      fetchData(startDate.toISOString(), endDate.toISOString())
    } else if (startDate) {
      fetchData(startDate.toISOString(), startDate.toISOString())
    } else if (endDate) {
      fetchData(endDate.toISOString(), endDate.toISOString())
    } else {
      fetchData()
    }
  }

  // const handleFilter1 = () => {
  //   if (startDate1 && endDate1) {
  //     fetchUserTaskData(startDate1.toISOString(), endDate1.toISOString())
  //   } else if (startDate1) {
  //     fetchUserTaskData(startDate1.toISOString(), startDate1.toISOString())
  //   } else if (endDate1) {
  //     fetchUserTaskData(endDate1.toISOString(), endDate1.toISOString())
  //   } else {
  //     fetchUserTaskData()
  //   }
  // }

  useEffect(() => {
    fetchData()
    // fetchUserTaskData()
  }, [])


  const formatNumber = number => {
    return new Intl.NumberFormat('en-US').format(number)
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const filteredTasks = taskCompletionCounts.filter(
    task =>
      task.taskName.toLowerCase().includes(filterText.toLowerCase()) ||
      task.category.toLowerCase().includes(filterText.toLowerCase())
  )

  const sortedTasks = filteredTasks.sort((a, b) => {
    if (orderBy === 'taskName') {
      return order === 'asc'
        ? a.taskName.localeCompare(b.taskName)
        : b.taskName.localeCompare(a.taskName)
    } else if (orderBy === 'category') {
      return order === 'asc'
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category)
    }
    return 0
  })



  return (
    <Page title='Dashboard | Community Stats'>
      <Container
        maxWidth='xl'
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ pb: 5, textAlign: 'center' }}>
          <Typography variant='h4' color='white'>
            Welcome To TGEPad Bot Admin Panel
          </Typography>
        </Box>

        {/* General Statistics */}
        <Grid container spacing={3} sx={{ mb: 4 }} justifyContent='flex-start'>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                count={formatNumber(data.totalUsers)}
                name='Total Users'
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                className='skeleton'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Total Taps'
                count={formatNumber(data.totalTaps)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Total Life-Time Points'
                count={formatNumber(data.totalLifetimePoints)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Active Users'
                count={formatNumber(data.activeUsers)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Current Active Users'
                count={formatNumber(data.currentActiveUsers)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Total Task Completed'
                count={formatNumber(data.totalTasksCompleted)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isLoading ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{ borderRadius: '16px', height: 150 }}
              />
            ) : (
              <AppVerifiedUsers
                name='Redeemed Points'
                count={formatNumber(data.redeemedPoints)}
              />
            )}
          </Grid>
        </Grid>

        {/* Date Range Picker */}
        {/* <Grid
          container
          sx={{ mb: 4 }}
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item sx={{ width: 200, mr: 1 }}>
            <DatePicker
              label='Start Date'
              value={startDate1}
              onChange={newValue => setStartDate1(newValue)}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item sx={{ width: 200, mx: 1 }}>
            <DatePicker
              label='End Date'
              value={endDate1}
              onChange={newValue => setEndDate1(newValue)}
              renderInput={params => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleFilter1}
              fullWidth
            >
              Filter
            </Button>
          </Grid>
        </Grid> */}

        {/* <Box sx={{ width: '100%', height: '500px', position: 'relative' }}>
          <Typography
            variant='h4'
            color='white'
            sx={{ textAlign: 'center', mb: 2 }}
          >
            User's Per Task
          </Typography>

          {isLoading ? (
            <Skeleton variant='rectangular' width='100%' />
          ) : taskCompletionCounts.length > 0 ? (
            <TaskCompletionChart
              key={JSON.stringify(taskCompletionCounts)}
              taskCompletionCounts={taskCompletionCounts}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px', // or any height that fits your needs
                width: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Typography variant='h6' color='white' textAlign='center'>
                No task completion data available for the selected date range
              </Typography>
            </Box>
          )}
        </Box> */}
      </Container>
    </Page>
  )
}
