// import React, { useState } from 'react'
// import axios from 'axios'
// import { Typography, TextField, Button, Box, Modal } from '@mui/material'
// import { styled } from '@mui/material/styles'
// import { useSnackbar } from '../layouts/dashboard/SnackbarContext'

// const ModalContent = styled(Box)(({ theme }) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   backgroundColor: theme.palette.background.paper,
//   borderRadius: 8,
//   boxShadow: theme.shadows[5],
//   padding: theme.spacing(4),
//   outline: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   zIndex: theme.zIndex.modal
// }))

// const ForgotPassword = ({ open, onClose }) => {
//   const [email, setEmail] = useState('')
//   const { showSnackbar } = useSnackbar()

//   const handleSubmit = async e => {
//     e.preventDefault()

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/admin/forgot-password`,
//         { email }
//       )
//       showSnackbar('Reset link sent to your email!')
//       onClose()
//     } catch (error) {
//       showSnackbar(
//         error.response?.data?.message || 'An error occurred',
//         'error'
//       )
//     }
//   }

//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       aria-labelledby='forgot-password-modal'
//       aria-describedby='forgot-password-modal-description'
//     >
//       <ModalContent style={{ background: 'black' }}>
//         <Typography variant='h4' gutterBottom>
//           Forgot Password
//         </Typography>
//         <Typography variant='body1' color='text.secondary' sx={{ mb: 4 }}>
//           Enter your email address and we'll send you a link to reset your
//           password.
//         </Typography>
//         <Box
//           component='form'
//           onSubmit={handleSubmit}
//           sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
//         >
//           <TextField
//             type='email'
//             label='Enter your email'
//             variant='outlined'
//             value={email}
//             onChange={e => setEmail(e.target.value)}
//             required
//             sx={{ mb: 2 }}
//           />
//           <Button type='submit' variant='contained' color='primary'>
//             Send Reset Link
//           </Button>
//         </Box>
//       </ModalContent>
//     </Modal>
//   )
// }

// export default ForgotPassword


















import React, { useState } from 'react';
import axios from 'axios';
import { Typography, TextField, Button, Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from '../layouts/dashboard/SnackbarContext';

const SignUpModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const SignUpContent = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(20px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  padding: theme.spacing(4),
  width: '400px',
  maxWidth: '90%',
  color: 'white',
  position: 'relative',
  animation: 'modalFade 0.3s ease-out',
  '@keyframes modalFade': {
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' }
  }
}));

const BlurOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: theme.zIndex.modal - 1
}));

const ForgotPassword = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/forgot-password`,
        { email }
      );
      showSnackbar('Reset link sent to your email!', 'success');
      onClose();
    } catch (error) {
      showSnackbar(
        error.response?.data?.message || 'An error occurred',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {open && <BlurOverlay />}
      <SignUpModal
        open={open}
        onClose={onClose}
        aria-labelledby="forgot-password-modal"
        aria-describedby="forgot-password-modal-description"
      >
        <SignUpContent>
          <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
            Forgot Password
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 3, 
              textAlign: 'center',
              color: 'rgba(255, 255, 255, 0.7)'
            }}
          >
            Enter your email address and we'll send you a link to reset your
            password.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <TextField
              fullWidth
              type="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              disabled={isLoading}
              sx={{
                bgcolor: '#5E35FF',
                '&:hover': {
                  bgcolor: '#4925CC',
                },
                opacity: isLoading ? 0.7 : 1,
                cursor: isLoading ? 'not-allowed' : 'pointer'
              }}
            >
              {isLoading ? 'Sending...' : 'Send Reset Link'}
            </Button>
          </Box>
        </SignUpContent>
      </SignUpModal>
    </>
  );
};

export default ForgotPassword;