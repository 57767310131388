import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Box, Modal, TextField, Button } from '@mui/material';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import LArt from '../assets/Vector.png';
import { useSnackbar } from '../layouts/dashboard/SnackbarContext';
import ForgotPassword from '../pages/ForgotPasswordDialog';


const ContentCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  padding: theme.spacing(3),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 30px rgba(94, 53, 255, 0.2)'
  }
}));

const SignUpModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const SignUpContent = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(20px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  padding: theme.spacing(4),
  width: '400px',
  maxWidth: '90%',
  color: 'white',
  position: 'relative',
  animation: 'modalFade 0.3s ease-out',
  '@keyframes modalFade': {
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' }
  }
}));



const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  backgroundColor: '#030303'
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#030303',
  // backgroundImage: `url(${LArt})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '20px',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(1px)'
  }
}))

const WelcomeContainer = styled(Box)({
  position: 'relative',
  zIndex: 1,
  padding: '40px',
  marginTop: '100px'
})

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

const BlurOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(10px)',
  zIndex: theme.zIndex.modal - 1
}))

// Main component
export default function Login() {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  return (
    <RootStyle title="Login | TGEPad">
      {/* <AnimatedStars /> */}
      <MHidden width="mdDown">
        <SectionStyle>
          <WelcomeContainer sx={{ marginTop: "200px" }}>
            <Typography
              variant="h3"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                marginBottom: '20px',
                position: 'relative',
                zIndex: 1,
                textShadow: '0 0 20px rgba(94, 53, 255, 0.5)'
              }}
            >
              Welcome to Admin Panel
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: 'rgba(255,255,255,0.8)',
                maxWidth: '80%',
                lineHeight: 1.6,
                textAlign: 'center',
                margin: '0 auto',
                position: 'relative',
                zIndex: 1
              }}
            >
              Manage your application with our powerful admin dashboard
            </Typography>
          </WelcomeContainer>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <ContentCard
       sx={{
        position: 'relative',
        zIndex: 1,
        border: '2px solid transparent', // Transparent border for gradient overlay
        borderRadius: 2, // Rounded corners for the card
        background: `
          linear-gradient(black, black) padding-box, /* Card background */
          linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) border-box /* Gradient border */
        `,
      }}

          >
            <Stack sx={{ mb: 5, position: 'relative', zIndex: 1 }}>
              <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
                Sign in to TGEPad Bot Admin
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Enter your details below.
              </Typography>
            </Stack>

            <LoginForm />

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
              <Link
                component="button"
                variant="subtitle2"
                onClick={() => setForgotPasswordOpen(true)}
                sx={{
                  background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  textDecoration: 'none',
                  fontWeight: 600,
                  '&:hover': {
                    textDecoration: 'underline',
                    opacity: 0.9,
                  },
                }}
              >
                Forgot password?
              </Link>
            </Stack>

          </ContentCard>
        </ContentStyle>
      </Container>

      {forgotPasswordOpen && <BlurOverlay />}
      <ForgotPassword
        open={forgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
      />
    </RootStyle>
  );
}