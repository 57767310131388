import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";

import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/User";
import Task from "./pages/Task"
import League from "./pages/League"
import Boost from "./pages/Boost"
import ResetPassword from "./pages/PasswordResetDialog";
import MassNotification from "./pages/MassNotification"
import Partners from "./pages/Partners"
import DemographicsChart from "./components/charts/DemographicsChart";
import ReorderTask from "./pages/ReorderTask";
import AddAdmin from "./pages/AddAdmin";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp /> },
        { path: "user", element: <User /> },
        // { path: "Task", element: <Task /> },
        // { path: "League", element: <League /> },
        // { path: "Boost", element: <Boost /> },
        { path: "MassNotification", element: <MassNotification /> },
        // { path: "Partners", element: <Partners /> },
        // { path: "DemographicsChart", element: <DemographicsChart/>},
        // { path: "ReorderTask", element: <ReorderTask/>},
        { path: "AddAdmin", element: <AddAdmin/>}
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Login /> },
        { path: "reset-password/:token", element: <ResetPassword /> },
      ],
    },
    { path: "*", element: <Navigate to="/dashboard/app" replace /> },
  ]);
}



