import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  InputAdornment,
  Switch
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
// import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { backendServices } from '../services/backendService'
import { toaster } from 'src/utils/toast';

const AdminDashboard = () => {
  const [admins, setAdmins] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [showPassword, setShowPassword] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState(null)
  const [editAdmin, setEditAdmin] = useState({
    email: '',
    username: '',
    password: '',
    role: 'admin',
    isActive: true
  })
  const [newAdmin, setNewAdmin] = useState({
    email: '',
    username: '',
    password: '',
    role: 'admin'
  })

  useEffect(() => {
    fetchAdmins(currentPage)
  }, [currentPage])

  const fetchAdmins = async page => {
    setLoading(true)
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/admin/getadmins`
      )

      setAdmins(response.data.admins)
      setCurrentPage(response.data.currentPage)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.error('No Admin Data Found:', error)
      // toaster('No Admin Data Found', "error")
    } finally {
      setLoading(false)
    }
  }

  const handleAddAdmin = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/admin/add`,
        {
          email: newAdmin.email,
          username: newAdmin.username,
          password: newAdmin.password,
          role: newAdmin.role
        }
      )

      if (response.status === 200) {
        setOpenDialog(false)
        fetchAdmins(currentPage)
        setNewAdmin({ email: '', username: '', password: '', role: 'admin' })
        toaster('Admin added successfully', "success")
      } else {
        throw new Error(response.data.message || 'Failed to add admin')
      }
    } catch (error) {
      console.error('Error adding admin:', error)
      toaster('Error occurred during adding admin', "error")
    }
  }


  const handleEditAdmin = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/admin/editAdmin/${selectedAdmin._id}`,
        editAdmin
      )

      if (response.status === 200) {
        setOpenEditDialog(false)
        fetchAdmins(currentPage)
        toaster('Admin updated successfully', "success")
      }
    } catch (error) {
      console.error('Error updating admin:', error)
      toaster('Error updating admin', "error")

    }
  }

  const handleStatusToggle = async (adminId, currentStatus) => {
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/admin/editAdmin/${adminId}`,
        {
          isActive: !currentStatus
        }
      )

      if (response.status === 200) {
        fetchAdmins(currentPage)
        toaster('Admin status updated successfully', "success")
      }
    } catch (error) {
      toaster('Error updating admin status', "error")
    }
  }

  const openEdit = admin => {
    setSelectedAdmin(admin)
    setEditAdmin({
      email: admin.email,
      username: admin.username,
      role: admin.role,
      isActive: admin.isActive,
      password: ''
    })
    setOpenEditDialog(true)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const formatDate = dateString => {
    return new Date(dateString).toLocaleString()
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <AppBar
        position='static'
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <Typography variant='h4'>Admin Management</Typography>
        </Toolbar>
      </AppBar>

      <Card
        style={{
          background: 'black',
          color: 'white',
          margin: '20px',
          height: 'calc(100vh - 40px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '30px' }}
        >
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background
              color: '#fff', // White text
              fontWeight: 600,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                background: 'linear-gradient(29.39deg, #6328B5 3.5%, #DD29FF 84.18%)', // Reverse gradient on hover
                // color: '#000', // Black text color on hover
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            Add Admin
          </Button>

        </Box>

        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100vh'
            marginTop='-50px'
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper} style={{ background: 'black', color: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        color: 'transparent', // Make text color transparent
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background
                        backgroundClip: 'text', // Apply the gradient to the text
                        WebkitBackgroundClip: 'text', // Ensure it works in WebKit-based browsers
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent',
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Username
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent',
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent',
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Role
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent',
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent',
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Created
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent',
                        background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                      }}
                    >
                      Actions
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map(admin => (
                    <TableRow key={admin._id}>
                      <TableCell style={{ color: 'white' }}>{admin.email}</TableCell>
                      <TableCell style={{ color: 'white' }}>{admin.username}</TableCell>
                      <TableCell style={{ color: 'white' }}>
                        {admin.password.slice(0, 6)}...{admin.password.slice(-6)}
                      </TableCell>
                      <TableCell style={{ color: 'white' }}>{admin.role}</TableCell>
                      <TableCell>
                        <Switch
                          checked={admin.isActive}
                          onChange={() => handleStatusToggle(admin._id, admin.isActive)}
                          color="primary"
                          inputProps={{
                            'aria-label': 'controlled'
                          }}
                          sx={{
                            '& .MuiSwitch-thumb': {
                              background: admin.isActive ? 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)' : '#fff', // Show gradient when on, white when off
                              borderRadius: '50%', // Ensures the thumb remains circular
                            },
                            '& .MuiSwitch-track': {
                              background: admin.isActive ? 'linear-gradient(45deg, #DD29FF 30%, #6328B5 90%)' : '#eaeaea', // Gradient when on, light gray when off
                              border: admin.isActive ? 'none' : '2px solid #6328B5', // Border only when off
                            },
                          }}
                        />

                      </TableCell>
                      <TableCell style={{ color: 'white' }}>{formatDate(admin.createdAt)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => openEdit(admin)} color="primary">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}
            >
              <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography sx={{ mx: 2, color: 'white' }}>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </Box>
          </>
        )}


        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              boxShadow: 'none',
              border: '2px solid transparent', // Transparent border to show the gradient
              borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Gradient border
              borderRadius: '12px', // Optional: rounded corners for the card
            },
          }}
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
          }}
        >
          <DialogTitle style={{ background: 'black' }}>
            Add New Admin
          </DialogTitle>
          <form onSubmit={handleAddAdmin}>
            <DialogContent style={{ background: 'black' }}>
              <TextField
                autoFocus
                margin='dense'
                label='Email'
                type='email'
                fullWidth
                required
                value={newAdmin.email}
                onChange={e =>
                  setNewAdmin({ ...newAdmin, email: e.target.value })
                }
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Username'
                fullWidth
                required
                value={newAdmin.username}
                onChange={e =>
                  setNewAdmin({ ...newAdmin, username: e.target.value })
                }
                style={{ marginBottom: '16px' }}
              />

              <TextField
                margin='dense'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={newAdmin.password}
                onChange={e =>
                  setNewAdmin({ ...newAdmin, password: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge='end'
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Role'
                fullWidth
                value={newAdmin.role}
                InputProps={{
                  readOnly: true
                }}
                style={{ marginBottom: '16px' }}
              />
            </DialogContent>
            <DialogActions style={{ background: 'black' }}>
              <Button
                onClick={() => setOpenDialog(false)}
                style={{
                  background: 'transparent', // No background color for the button
                  border: 'none', // Remove the default border
                  borderRadius: '8px', // Optional: rounded corners for the button
                  padding: '8px 16px', // Adjust padding for spacing
                  cursor: 'pointer', // Optional: add cursor pointer on hover
                  fontWeight: 'bold', // Optional: make the text bold
                  background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background for text
                  WebkitBackgroundClip: 'text', // Clip the background to the text
                  color: 'transparent', // Make the text transparent so the background shows through
                }}
              >
                Cancel
              </Button>


              <Button
                type="submit"
                variant="contained"
                style={{
                  background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background
                  border: 'none', // Remove the default border
                  borderRadius: '8px', // Optional: rounded corners for the button
                  color: 'white', // Button text color
                  padding: '8px 16px', // Adjust padding for spacing
                  cursor: 'pointer', // Optional: add cursor pointer on hover
                }}
              >
                Add Admin
              </Button>

            </DialogActions>
          </form>
        </Dialog>
        {/* Add Edit Admin Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              boxShadow: 'none',
              border: '2px solid transparent', // Transparent border to show the gradient
              borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Gradient border
              borderRadius: '12px', // Optional: rounded corners for the card
            },
          }}
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
          }}
        >
          <DialogTitle style={{ background: 'black' }}>Edit Admin</DialogTitle>
          <form onSubmit={handleEditAdmin}>
            <DialogContent style={{ background: 'black' }}>
              <TextField
                margin='dense'
                label='Email'
                type='email'
                fullWidth
                value={editAdmin.email}
                onChange={e => setEditAdmin({ ...editAdmin, email: e.target.value })}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Username'
                fullWidth
                value={editAdmin.username}
                onChange={e => setEditAdmin({ ...editAdmin, username: e.target.value })}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='New Password (optional)'
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={editAdmin.password}
                onChange={e => setEditAdmin({ ...editAdmin, password: e.target.value })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Role'
                fullWidth
                value={editAdmin.role}
                InputProps={{
                  readOnly: true
                }}
                style={{ marginBottom: '16px' }}
              />
            </DialogContent>
            <DialogActions style={{ background: 'black' }}>
              <Button
                onClick={() => setOpenEditDialog(false)}
                style={{
                  background: 'transparent', // No background color for the button
                  border: 'none', // Remove the default border
                  borderRadius: '8px', // Optional: rounded corners for the button
                  padding: '8px 16px', // Adjust padding for spacing
                  cursor: 'pointer', // Optional: add cursor pointer on hover
                  fontWeight: 'bold', // Optional: make the text bold
                  background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background for text
                  WebkitBackgroundClip: 'text', // Clip the background to the text
                  color: 'transparent', // Make the text transparent so the background shows through
                }}
              >
                Cancel
              </Button>


              <Button
                type="submit"
                variant="contained"
                style={{
                  background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background
                  border: 'none', // Remove the default border
                  borderRadius: '8px', // Optional: rounded corners for the button
                  color: 'white', // Button text color
                  padding: '8px 16px', // Adjust padding for spacing
                  cursor: 'pointer', // Optional: add cursor pointer on hover
                }}
              >
                Update Admin
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Card>
    </>
  )
}

export default AdminDashboard
