// import React, { useState, useEffect } from 'react'
// import { useParams, useNavigate } from 'react-router-dom'
// import axios from 'axios'
// import {
//   Container,
//   Typography,
//   TextField,
//   Button,
//   Box,
//   Modal,
//   Backdrop
// } from '@mui/material'
// import { styled } from '@mui/material/styles'
// import { useSnackbar } from '../layouts/dashboard/SnackbarContext'

// const RootStyle = styled(Container)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   minHeight: '100vh',
//   backgroundColor: theme.palette.background.default,
//   padding: theme.spacing(4),
//   position: 'relative'
// }))

// const ModalContent = styled(Box)(({ theme }) => ({
//   width: 400,
//   backgroundColor: theme.palette.background.paper,
//   borderRadius: 8,
//   boxShadow: theme.shadows[5],
//   padding: theme.spacing(4),
//   outline: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center'
// }))

// const BackdropStyle = styled(Backdrop)(({ theme }) => ({
//   backdropFilter: 'blur(8px)' // Adding blur effect
// }))

// const ResetPassword = () => {
//   const { token } = useParams()
//   const navigate = useNavigate()
//   const [newPassword, setNewPassword] = useState('')
//   const [confirmPassword, setConfirmPassword] = useState('')
//   const [open, setOpen] = useState(true)
//   const { showSnackbar } = useSnackbar()

//   useEffect(() => {
//     // Optionally, add logic to verify the token validity here if needed
//   }, [token])

//   const handleSubmit = async e => {
//     e.preventDefault()

//     if (newPassword !== confirmPassword) {
//       showSnackbar('Passwords do not match', 'error')
//       return
//     }

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/admin/reset-password/${token}`,
//         { newPassword }
//       )
//       showSnackbar(response.data.message)
//       if (response.data.status) {
//         setOpen(false)
//         setTimeout(() => {
//           navigate('/', { replace: true })
//         }, 100)
//       }
//     } catch (error) {
//       showSnackbar(
//         error.response?.data?.message || 'An error occurred',
//         'error'
//       )
//     }
//   }

//   return (
//     <>
//       <BackdropStyle open={open}>
//         <ModalContent style={{ background: 'black' }}>
//           <Typography variant='h4' gutterBottom>
//             Reset Password
//           </Typography>
//           <Typography variant='body1' color='text.secondary' sx={{ mb: 4 }}>
//             Enter your new password below.
//           </Typography>
//           <Box
//             component='form'
//             onSubmit={handleSubmit}
//             sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
//           >
//             <TextField
//               type='password'
//               label='New Password'
//               variant='outlined'
//               value={newPassword}
//               onChange={e => setNewPassword(e.target.value)}
//               required
//               sx={{ mb: 2 }}
//             />
//             <TextField
//               type='password'
//               label='Confirm New Password'
//               variant='outlined'
//               value={confirmPassword}
//               onChange={e => setConfirmPassword(e.target.value)}
//               required
//               sx={{ mb: 2 }}
//             />
//             <Button type='submit' variant='contained' color='primary'>
//               Reset Password
//             </Button>
//           </Box>
//         </ModalContent>
//       </BackdropStyle>
//     </>
//   )
// }

// export default ResetPassword
















import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Typography, TextField, Button, Box, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from '../layouts/dashboard/SnackbarContext';

const SignUpModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const SignUpContent = styled(Box)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(20px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  padding: theme.spacing(4),
  width: '400px',
  maxWidth: '90%',
  color: 'white',
  position: 'relative',
  animation: 'modalFade 0.3s ease-out',
  '@keyframes modalFade': {
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' }
  }
}));

const BlurOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: theme.zIndex.modal - 1
}));

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [open, setOpen] = useState(true);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    // Optionally, add logic to verify the token validity here if needed
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      showSnackbar('Passwords do not match', 'error');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/reset-password/${token}`,
        { newPassword }
      );
      showSnackbar(response.data.message);
      if (response.data.status) {
        setOpen(false);
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 100);
      }
    } catch (error) {
      showSnackbar(
        error.response?.data?.message || 'An error occurred',
        'error'
      );
    }
  };

  return (
    <>
      {open && <BlurOverlay />}
      <SignUpModal
        open={open}
        aria-labelledby="reset-password-modal"
        aria-describedby="reset-password-modal-description"
      >
        <SignUpContent>
          <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
            Reset Password
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 3, 
              textAlign: 'center',
              color: 'rgba(255, 255, 255, 0.7)'
            }}
          >
            Enter your new password below.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <TextField
              fullWidth
              type="password"
              label="New Password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                  '& fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              }}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{
                bgcolor: '#5E35FF',
                '&:hover': {
                  bgcolor: '#4925CC',
                },
              }}
            >
              Reset Password
            </Button>
          </Box>
        </SignUpContent>
      </SignUpModal>
    </>
  );
};

export default ResetPassword;