import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Card,
  CircularProgress
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'react-toastify/dist/ReactToastify.css';
import { backendServices } from '../services/backendService';
import { toaster } from 'src/utils/toast';

const MassNotificationManager = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [sendAt, setSendAt] = useState(new Date());
  const [notifications, setNotifications] = useState([]);
  const [useDatetime, setUseDatetime] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPendingNotification, setIsPendingNotification] = useState(false);

  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  const fetchNotifications = async (page) => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/telNotification/notifications?page=${page}&limit=20`
      );

      setNotifications(response.data.data);
      setCurrentPage(response.data.currentPage);
      setTotalPages(response.data.totalPages);

      // Check if there's any notification in progress
      const pendingNotification = response.data.data.find(notification => notification.status === 'In Progress');
      const wasPending = isPendingNotification;
      setIsPendingNotification(!!pendingNotification);

      // If there was a pending notification and now it's completed, show a toast
      if (wasPending && !pendingNotification) {
        toaster('Previous notification processing completed. You can now send a new notification', "success")
      }
    } catch (error) {
      console.error('No Notifications Found:', error);
      // toaster('No Notifications Found', "error")
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    if (isPendingNotification) {
      toaster('A notification is currently being processed. Please wait for it to complete before sending a new one', "warn")
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setMessage('');
    setSendAt(new Date());
    setUseDatetime(false);
  };

  const handleSend = () => {
    if (imageFile && imageFile.size > 1 * 1024 * 1024) {
      toaster('Image size should not exceed 1MB', "error");
      return;
    }

    // Create FormData
    const payload = new FormData();
    payload.append('message', message);

    if (useDatetime) {
      payload.append('sendAt', sendAt);
    }

    if (imageFile) {
      payload.append('file', imageFile);
    }

    // Debugging: Log FormData entries
    // console.log("FormData content:");
    // for (let [key, value] of payload.entries()) {
    //   console.log(`${key}:`, value instanceof File ? value.name : value);
    // }
    // console.log("response : ", payload);

    // Close the popup immediately
    handleClose();

    // Send the notification asynchronously
    backendServices.Post(
      `${process.env.REACT_APP_BASE_URL}/telNotification/sendMassNotification`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the correct content type
        },
      }
    )
      .then(() => {
        toaster('Notification sent successfully', "success")

        setIsPendingNotification(true);
        fetchNotifications(currentPage);
      })
      .catch((error) => {
        console.error('Error sending notification:', error);
        toaster('Error sending notification', "error")
      });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        toaster('Image size should not exceed 1MB', "error");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // For preview
      };
      reader.readAsDataURL(file);
      setImageFile(file); // For submission
    }
  };


  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          <Typography variant="h4">Mass Notification Manager</Typography>
        </Toolbar>
      </AppBar>
      <Card
        style={{
          background: 'black',
          color: 'white',
          margin: '20px',
          height: 'calc(100vh - 40px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '30px' }}>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background
              color: 'white', // Text color
              borderRadius: '8px', // Optional: rounded corners for the button
              padding: '8px 16px', // Optional: adjust padding
              '&:hover': {
                backgroundImage: 'linear-gradient(29.39deg, #6328B5 3.5%, #DD29FF 84.18%)', // Gradient hover effect (reversed or adjusted colors)
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', // Optional: add shadow on hover
              },
            }}
          >
            Send Notification
          </Button>

        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              boxShadow: 'none',
              border: '1px solid gray',
            }
          }}
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' }
          }}
        >
          <DialogTitle style={{ background: 'black' }}>
            Send Mass Notification
          </DialogTitle>
          <DialogContent style={{ background: 'black' }}>
            <Grid container spacing={2} alignItems="center">
              {/* Left side: Image preview */}
              <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded Preview"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                      borderRadius: '5px',
                      border: '1px solid gray',
                      marginBottom: '10px',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      height: '200px',
                      border: '1px dashed gray',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'gray',
                      marginBottom: '10px',
                    }}
                  >
                    No Image Uploaded
                  </div>
                )}
                <Button
                  variant="outlined"
                  component="label"
                  style={{
                    color: 'white',
                    borderColor: 'gray',
                    marginTop: '10px',
                  }}
                >
                  Upload Image
                  <input
                    type="file"
                    accept=".jpeg,.jpg,.png"
                    hidden
                    onChange={(e) => handleImageUpload(e)}
                  />
                </Button>
              </Grid>

              {/* Right side: Message input */}
              <Grid item xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Notification Message"
                  fullWidth
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{
                    color: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ background: 'black' }}>
            <Button
              onClick={handleClose}
              sx={{
                color: 'transparent',
                backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                fontWeight: 'bold',
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={handleSend}
              sx={{
                color: 'transparent',
                backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                fontWeight: 'bold',
              }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>


        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh" marginTop="-50px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper} style={{ background: 'black', color: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        color: 'transparent', // Makes the text transparent
                        backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient for text
                        backgroundClip: 'text', // Clips the background to the text
                        WebkitBackgroundClip: 'text', // For Webkit-based browsers (like Safari)
                      }}
                    >
                      Image
                    </TableCell>
                    <TableCell
                      style={{
                        color: 'transparent', // Makes the text transparent
                        backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient for text
                        backgroundClip: 'text', // Clips the background to the text
                        WebkitBackgroundClip: 'text', // For Webkit-based browsers (like Safari)
                      }}
                    >
                      Message
                    </TableCell>

                    <TableCell
                      style={{
                        color: 'transparent', // Makes the text transparent
                        backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient for text
                        backgroundClip: 'text', // Clips the background to the text
                        WebkitBackgroundClip: 'text', // For Webkit-based browsers (like Safari)
                      }}
                    >
                      Send At
                    </TableCell>

                    <TableCell
                      style={{
                        color: 'transparent', // Makes the text transparent
                        backgroundImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient for text
                        backgroundClip: 'text', // Clips the background to the text
                        WebkitBackgroundClip: 'text', // For Webkit-based browsers (like Safari)
                      }}
                    >
                      Status
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {notifications.map((notification) => (
                    <TableRow key={notification._id}>
                      <TableCell style={{ color: 'white' }}>
                        <img
                          src={notification.uploadedImage}
                          alt="Uploaded Preview"
                          style={{
                            maxWidth: '200px',  // Increased width for a larger image
                            maxHeight: '200px', // Increased height for a larger image
                            objectFit: 'contain', // Keeps the aspect ratio intact
                            borderRadius: '5px', // Optional, for rounded corners
                          }}
                        />
                      </TableCell>

                      <TableCell style={{ color: 'white', textAlign: 'justify' }}>
                        {notification.message}
                      </TableCell>
                      <TableCell style={{ color: 'white' }}>
                        {new Date(notification.sendAt).toLocaleString()}
                      </TableCell>
                      <TableCell style={{ color: 'white' }}>
                        {notification.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography sx={{ mx: 2 }}>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Next
              </Button>
            </Box>
          </>
        )}
      </Card>
    </>
  );
};

export default MassNotificationManager;