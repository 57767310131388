import PropTypes from "prop-types";
import LogoImg from "../assets/tgepad.png";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return <img src={LogoImg} style={{width:"300px", marginLeft:"103px"}} alt="Astraeus Bot" />;
}
