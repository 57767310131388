import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { services } from '../../../services';

import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Email or Username is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values) => {
      const url = `${process.env.REACT_APP_BASE_URL}/admin/login`;

      // Send a single field for either email or username
      const data = {
        username: values.username,
        password: values.password
      };

      try {
        const loginUser = await services.Post(url, data);
        if (loginUser.status === 200) {
          const token = loginUser.data.data.token;
          const role = loginUser.data.data.user.role;
          localStorage.setItem('token', token);
          localStorage.setItem('role', role);
          navigate('/dashboard/app', { replace: true });
        }
      } catch (error) {
        console.error('Login error:', error);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword(show => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete='email username'
            type='text'
            label='Email or Username'
            placeholder='Enter your email or username'
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete='current-password'
            type={showPassword ? 'text' : 'password'}
            label='Password'
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleShowPassword} edge='end'>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ my: 2 }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
            color: '#FFFFFF',
            boxShadow: '0px 4px 12px rgba(221, 41, 255, 0.4)', // Optional for extra flair
            '&:hover': {
              background: 'linear-gradient(29.39deg, #6328B5 3.5%, #DD29FF 84.18%)',
              boxShadow: '0px 6px 18px rgba(221, 41, 255, 0.6)', // Optional hover effect
            },
          }}
        >
          Login
        </LoadingButton>

      </Form>
    </FormikProvider>
  );
}