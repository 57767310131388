import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleIcon from '@mui/icons-material/People'
import TaskIcon from '@mui/icons-material/Task'
import LeagueIcon from '@mui/icons-material/SportsEsports'
import BoostIcon from '@mui/icons-material/FlashOn'
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
// ----------------------------------------------------------------------

const getIcon = IconComponent => (
  <IconComponent sx={{ width: 22, height: 22, color: 'white' }} />
)

export const adminsidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(DashboardIcon)
  },
  {
    title: 'User Details',
    path: '/dashboard/user',
    icon: getIcon(PeopleIcon)
  }
  // {
  //   title: 'Task',
  //   path: '/dashboard/Task',
  //   icon: getIcon(TaskIcon),
  // },
  // {
  //   title: 'League',
  //   path: '/dashboard/League',
  //   icon: getIcon(LeagueIcon),
  // },
  // {
  //   title: 'Boost',
  //   path: '/dashboard/Boost',
  //   icon: getIcon(BoostIcon),
  // },
]

export const superAdminSidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(DashboardIcon)
  },
  {
    title: 'User Details',
    path: '/dashboard/user',
    icon: getIcon(PeopleIcon)
  },
  // {
  //   title: 'Demographics',
  //   path: '/dashboard/DemographicsChart',
  //   icon: getIcon(GroupsIcon)
  // },
  {
    title: 'Admin',
    path: '/dashboard/AddAdmin',
    icon: getIcon(PersonAddIcon)
  },
  // {
  //   title: 'Task',
  //   path: '/dashboard/Task',
  //   icon: getIcon(TaskIcon)
  // },
  // {
  //   title: 'League',
  //   path: '/dashboard/League',
  //   icon: getIcon(LeagueIcon)
  // },
  // {
  //   title: 'Boost',
  //   path: '/dashboard/Boost',
  //   icon: getIcon(BoostIcon)
  // },
  // not required for this client
  {
    title: 'Notification',
    path: '/dashboard/MassNotification',
    icon: getIcon(NotificationsIcon)
  },
  // {
  //   title: 'Partners',
  //   path: '/dashboard/Partners',
  //   icon: getIcon(GroupsIcon)
  // },

]



