import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material'
// components
import Logo from '../../components/Logo'
import Scrollbar from '../../components/Scrollbar'
import NavSection from '../../components/NavSection'
import { MHidden } from '../../components/@material-extend'
//
import { adminsidebarConfig, superAdminSidebarConfig } from './SidebarConfig'
import account from '../../_mocks_/account'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}))

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2.5, 3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[900],
  transition: 'all 0.3s ease-in-out',
  border: `1px solid ${theme.palette.grey[800]}`,
}))

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation()

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: 'linear-gradient(180deg, #1a1a1a 0%, #0a0a0a 100%)',
        },
        '& .simplebar-scrollbar:before': {
          background: 'rgba(255, 255, 255, 0.2)'
        }
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
        }}
      >
        <Box
          component={RouterLink}
          to='/dashboard/app'
          sx={{
            display: 'inline-flex',
            width: "200px",
            height: "90px",
            marginLeft: "-60px",
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)'
            }
          }}
        >
          <Logo />
        </Box>
      </Box>

      <Box
        sx={{
          mb: 5,
          mx: 2.5,
          mt: 4,
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-24px',
            left: '10%',
            width: '80%',
            height: '1px',
            background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.1), transparent)'
          }
        }}
      >
        <Link
          underline='none'
          component={RouterLink}
          to='#'
          sx={{
            display: 'block',
            transition: 'transform 0.2s ease',
            '&:hover': {
              transform: 'translateY(-2px)'
            }
          }}
        >
          <AccountStyle>
            <Avatar
              src={account.photoURL}
              alt="photoURL"
              sx={{
                width: 48,
                height: 48,
                border: '2px solid transparent', // Transparent border for gradient application
                borderRadius: '50%', // Ensure the shape is circular
                backgroundClip: 'padding-box', // Clip the background within the inner box
                background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background for border
                padding: '2px', // Space for the border thickness
                '& img': {
                  borderRadius: '50%', // Ensure the image inside remains circular
                  width: '100%',
                  height: '100%',
                  display: 'block',
                },
                '&:hover': {
                  transform: 'scale(1.05)', // Scale effect on hover
                  transition: 'transform 0.2s ease',
                },
              }}
            />

            <Box sx={{ ml: 2 }}>
              <Typography
                variant='subtitle2'
                sx={{
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  background: 'linear-gradient(90deg, #DD29FF, #6328B5)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                {`User`}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '0.875rem',
                  mt: 0.5,
                  background: 'linear-gradient(90deg, #6328B5, #DD29FF)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                {localStorage.getItem('role')}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection
        navConfig={
          localStorage.getItem('role') == 'superadmin'
            ? superAdminSidebarConfig
            : adminsidebarConfig
        }
        sx={{
          '& .MuiListItemButton-root': {
            borderRadius: '8px',
            mx: 1.5,
            mb: 0.5,
            transition: 'all 0.2s ease',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.1)',
              transform: 'translateX(4px)',
            },
          },
          // Apply the gradient only to the active button
          '& .MuiListItemButton-root.active': {
            background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient for active button
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.1)',
              transform: 'translateX(4px)', // Keep the hover effect
            },
          },
          '& .MuiListItemIcon-root': {
            color: 'grey.400',
            minWidth: 36,
          },
          '& .MuiListItemText-root': {
            color: '#fff',
          },
        }}
      />


      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  )

  return (
    <RootStyle>
      <MHidden width='lgUp'>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: 'transparent',
              backgroundImage: 'none',
              boxShadow: '0 0 30px rgba(0,0,0,0.3)'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width='lgDown'>
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background: 'linear-gradient(180deg, #0a0a0a 0%, #1a1a1a 100%)',
              backgroundImage: 'none',
              borderRight: '1px solid rgba(255,255,255,0.05)',
              boxShadow: '4px 0 24px rgba(0,0,0,0.15)'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  )
}
