import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  TextField,
  Pagination,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Paper,
  FormControlLabel,
  Switch,
  MenuItem as MuiMenuItem,
  Checkbox,
  CircularProgress
} from '@mui/material'
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'
import moment from 'moment';
import { backendServices } from '../services/backendService'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import data from '@iconify/icons-eva/menu-2-fill'
import UserImg from '../assets/UserProfileImg.svg'

const TABLE_HEAD = [
  { id: 'userId', label: 'User ID', alignRight: false },
  { id: 'userName', label: 'Username', alignRight: false },
  { id: 'firstName', label: 'firstName', alignRight: false },
  { id: 'league', label: 'league', alignRight: false },
  { id: 'isBlockListed', label: 'Blacklisted', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
]

// Add this utility function for CSV creation
const convertToCSV = data => {
  const headers = ['User ID', 'Username', 'TON Address', 'Airdrop Points']
  const csvRows = [headers]

  data.forEach(item => {
    const row = [
      item.userId,
      item.userName,
      item.tonAddress || '', // New field replacing email
      // item.userLifeTimePoints || 0,
      item.AirdropPoints || 0 // New field
    ]
    csvRows.push(row)
  })

  return csvRows.map(row => row.join(',')).join('\n')
}

// // Add this component for date range selection
// const DateRangeSelector = ({ startDate, endDate, onStartDateChange, onEndDateChange, onClear }) => (
//   <LocalizationProvider dateAdapter={AdapterDateFns}>
//     <Stack direction="row" spacing={2} alignItems="center">
//       <DatePicker
//         label="Start Date"
//         value={startDate}
//         onChange={onStartDateChange}
//         renderInput={(params) => <TextField {...params} />}
//       />
//       <DatePicker
//         label="End Date"
//         value={endDate}
//         onChange={onEndDateChange}
//         renderInput={(params) => <TextField {...params} />}
//       />
//       <Button variant="outlined" onClick={onClear}>
//         Clear Dates
//       </Button>
//     </Stack>
//   </LocalizationProvider>
// )

const UserListToolbar = ({
  filterName,
  onFilterName,
  isBlockedFilter,
  onBlockedFilterChange,
  selected,
  onBulkBlocklist,
  onBulkUnblock,
  allSelectedBlocked,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onClearDates,
  totalUsers
}) => (
  <Stack direction='column' spacing={2} style={{ padding: '20px' }}>
    <Stack
      direction='row'
      spacing={2}
      justifyContent='space-between'
      alignItems='center'
    >
      <Stack direction='row' spacing={2}>
        <TextField
          label='Filter by Username'
          variant='outlined'
          size='medium'
          value={filterName}
          onChange={onFilterName}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '2px solid transparent', // Transparent border
                borderRadius: '12px', // Rounded corners
                borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Gradient border
              },
              '&:hover fieldset': {
                borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Gradient border on hover
              },
            },
          }}
        />

        <FormControl
          variant='outlined'
          size='medium'
          style={{ width: '200px' }}
        >
          <InputLabel>Filter by Blocked Status</InputLabel>
          <Select
            value={isBlockedFilter}
            onChange={onBlockedFilterChange}
            label='Filter by Blocked Status'
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '2px solid transparent', // Ensure border is transparent initially
                  borderRadius: '12px', // Rounded corners
                  borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Apply gradient border
                },
                '&:hover fieldset': {
                  borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Gradient border on hover
                },
              },
            }}
          >
            <MenuItem value='all' style={{ background: 'black' }}>
              All Users
            </MenuItem>
            <MenuItem value='blocked' style={{ background: 'black' }}>
              Blocked Users
            </MenuItem>
          </Select>

        </FormControl>
      </Stack>

      <Stack direction='row' spacing={2}>
        <Button
          variant="contained"
          sx={{
            background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
            color: '#fff',
            fontWeight: 600,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              background: 'linear-gradient(210.61deg, #DD29FF 3.5%, #6328B5 84.18%)', // Changes the gradient direction on hover
              transform: 'scale(1.05)',
              boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
            },
            '&.Mui-disabled': {
              background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Keep the gradient for the disabled state
              color: '#fff', // Keep the text white
              opacity: 0.4, // Make the button look disabled with lower opacity
              boxShadow: 'none', // Remove shadow when disabled
              pointerEvents: 'none', // Disable pointer events to avoid interaction
            },
          }}
          onClick={onBulkBlocklist}
          disabled={selected.length === 0 || allSelectedBlocked}
        >
          Block Selected
        </Button>

        <Button
          variant="contained"
          sx={{
            background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
            color: '#fff',
            fontWeight: 600,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              background: 'linear-gradient(210.61deg, #DD29FF 3.5%, #6328B5 84.18%)', // Changes the gradient direction on hover
              transform: 'scale(1.05)',
              boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
            },
            '&.Mui-disabled': {
              background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Keep the gradient for the disabled state
              color: '#fff', // Keep the text white
              opacity: 0.4, // Make the button look disabled with lower opacity
              boxShadow: 'none', // Remove shadow when disabled
              pointerEvents: 'none', // Disable pointer events to avoid interaction
            },
          }}
          onClick={onBulkUnblock}
          disabled={selected.length === 0 || !allSelectedBlocked}
        >
          Unblock Selected
        </Button>

        <Box
          sx={{
            mt: 2, // Adds spacing above the box
            p: 2, // Padding inside the box
            border: '2px solid transparent', // Make the border initially transparent
            borderRadius: '12px', // Rounded corners
            borderImage: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%) 1', // Apply gradient to the border
            backgroundColor: '#000', // Black background color
            color: '#fff', // White text for contrast
            textAlign: 'center', // Centers the text
            width: 'fit-content', // Adapts the width to the content
          }}
        >
          <strong>Total Users:</strong> {totalUsers}
        </Box>

      </Stack>

    </Stack>
    {/* <DateRangeSelector
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={onStartDateChange}
      onEndDateChange={onEndDateChange}
      onClear={onClearDates}
    /> */}
  </Stack>
)

const UserList = ({
  refreshTrigger,
  onView,
  onEdit,
  onDelete,
  onToggleBlacklist,
}) => {
  const [users, setUsers] = useState([])
  const [filterName, setFilterName] = useState('')
  const [isBlockedFilter, setIsBlockedFilter] = useState('all')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('userName')
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [totalPages, setTotalPages] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [selected, setSelected] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [allSelectedBlocked, setAllSelectedBlocked] = useState(false)
  const [loading, setLoading] = useState(true)

  const containerRef = useRef(null)
  const [tableHeight, setTableHeight] = useState('100%')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  useLayoutEffect(() => {
    const updateTableHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight
        const toolbarElement = containerRef.current.querySelector('.toolbar')
        const paginationElement = containerRef.current.querySelector(
          '.pagination-container'
        )

        const toolbarHeight = toolbarElement ? toolbarElement.clientHeight : 0
        const paginationHeight = paginationElement
          ? paginationElement.clientHeight
          : 0

        const newTableHeight =
          containerHeight - toolbarHeight - paginationHeight
        setTableHeight(`${newTableHeight}px`)
      }
    }

    // Add a small delay to ensure all elements are rendered
    const timeoutId = setTimeout(updateTableHeight, 0)

    window.addEventListener('resize', updateTableHeight)

    return () => {
      window.removeEventListener('resize', updateTableHeight)
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [refreshTrigger, page, filterName, order, orderBy, isBlockedFilter])

  const fetchUsers = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/admin/getAllUsers?page=${page}&limit=${rowsPerPage}&search=${filterName}&sortBy=${orderBy}&sortOrder=${order}&isBlockedFilter=${isBlockedFilter}`
      )
      setUsers(response.data.users || [])
      setTotalPages(response.data.totalPages)
      setTotalUsers(response.data.totalUsers)
      updateSelectedBlockedStatus(selected)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      // setLoading(false); // Hide loader after fetch completes or fails
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(1)
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value)
    setPage(1)
  }

  const handleBlockedFilterChange = event => {
    setIsBlockedFilter(event.target.value)
    setPage(1)
  }

  const handleToggleBlacklist = async (userId, currentStatus) => {
    try {
      await onToggleBlacklist(userId, !currentStatus)
      fetchUsers()
    } catch (error) {
      console.error('Error toggling blacklist status:', error)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOpenMenu = (event, user) => {
    setAnchorEl(event.currentTarget)
    setSelectedUser(user)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedUser(null)
  }

  const handleView = () => {
    if (selectedUser) {
      onView(selectedUser.userId)
    }
    handleCloseMenu()
  }

  const handleEdit = () => {
    if (selectedUser) {
      onEdit(selectedUser)
    }
    handleCloseMenu()
  }

  const handleDelete = () => {
    if (selectedUser) {
      onDelete(selectedUser.userId)
    }
    handleCloseMenu()
  }

  const updateSelectedBlockedStatus = selectedIds => {
    if (selectedIds.length === 0) {
      setAllSelectedBlocked(false)
      return
    }

    const allBlocked = selectedIds.every(
      id => users.find(user => user.userId === id)?.isBlockListed
    )
    setAllSelectedBlocked(allBlocked)
  }

  const handleSelectAll = event => {
    if (event.target.checked) {
      const newSelecteds = users.map(n => n.userId)
      setSelected(newSelecteds)
      updateSelectedBlockedStatus(newSelecteds)
      setSelectAll(true)
    } else {
      setSelected([])
      setSelectAll(false)
    }
  }

  const handleClick = (event, userId) => {
    const selectedIndex = selected.indexOf(userId)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    updateSelectedBlockedStatus(newSelected)
    setSelectAll(newSelected.length === users.length)
  }

  const handleBulkBlocklist = async () => {
    try {
      await Promise.all(
        selected.map(userId =>
          backendServices.Put(
            `${process.env.REACT_APP_BASE_URL}/admin/blacklist-user/${userId}`,
            { isBlockListed: true }
          )
        )
      )
      fetchUsers()
      setSelected([])
      setSelectAll(false)
      // console.log('Users blocklisted successfully')
    } catch (error) {
      console.error('Error blocklisting users:', error)
    }
  }

  const handleBulkUnblock = async () => {
    try {
      await Promise.all(
        selected.map(userId =>
          backendServices.Put(
            `${process.env.REACT_APP_BASE_URL}/admin/blacklist-user/${userId}`,
            { isBlockListed: false }
          )
        )
      )
      fetchUsers()
      setSelected([])
      setSelectAll(false)
      // console.log('Users unblocked successfully')
    } catch (error) {
      console.error('Error unblocking users:', error)
    }
  }



  // Add these handlers for date range
  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const handleClearDates = () => {
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <div
      ref={containerRef}
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Card
        style={{
          background: 'black',
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <UserListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          isBlockedFilter={isBlockedFilter}
          onBlockedFilterChange={handleBlockedFilterChange}
          selected={selected}
          onBulkBlocklist={handleBulkBlocklist}
          onBulkUnblock={handleBulkUnblock}
          allSelectedBlocked={allSelectedBlocked}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
          onClearDates={handleClearDates}
          totalUsers={totalUsers}
        />
        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100vh'
            marginTop='-50px'
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Paper
              sx={{
                flex: 1,
                overflow: 'hidden',
                background: 'black',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TableContainer
                sx={{
                  height: tableHeight, // Ensure the height is well-defined
                  overflowY: 'auto', // Enable vertical scrolling for the body
                  background: 'black', // Optional: Matches theme
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    width: '100%',
                    marginBottom: '50px',
                    borderCollapse: 'collapse', // Ensures no spacing issues
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        style={{
                          background: 'black',
                          position: 'sticky', // Ensure header stays in place
                          top: 0, // Essential for sticky positioning
                          zIndex: 1, // Higher than body rows
                        }}
                      >
                        <Checkbox
                          indeterminate={
                            selected.length > 0 && selected.length < users.length
                          }
                          checked={
                            users.length > 0 && selected.length === users.length
                          }
                          onChange={handleSelectAll}
                          sx={{
                            // Custom styles for the checkbox
                            '&.MuiCheckbox-root': {
                              backgroundColor: 'black', // Black background for the checkbox container
                              borderRadius: '4px', // Optional: rounded edges
                            },
                            '&.Mui-checked': {
                              color: '#DD29FF', // Custom color when checked
                            },
                            '& .MuiSvgIcon-root': {
                              fill: 'white', // Ensures the checkmark contrasts against the black
                            },
                            '&:hover': {
                              backgroundColor: '#333', // Slightly lighter hover effect
                            },
                          }}
                        />

                      </TableCell>
                      {TABLE_HEAD.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          style={{
                            background: 'black',
                            color: 'white',
                            fontWeight: 'bold',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.length > 0 ? (
                      users.map((row) => {
                        const isItemSelected = selected.indexOf(row.userId) !== -1;
                        return (
                          <TableRow
                            hover
                            key={row.userId}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onChange={(event) =>
                                  handleClick(event, row.userId)
                                }
                                onClick={(event) => event.stopPropagation()}
                              />
                            </TableCell>
                            <TableCell align="left">{row.userId}</TableCell>
                            <TableCell align="left">{row.userName}</TableCell>
                            <TableCell align="left">{row.firstName}</TableCell>
                            <TableCell align="left">{row.league}</TableCell>
                            <TableCell align="left">
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={row.isBlockListed}
                                    onChange={() =>
                                      handleToggleBlacklist(row.userId, row.isBlockListed)
                                    }
                                    color="primary"
                                    sx={{
                                      '& .MuiSwitch-track': {
                                        background: 'transparent',
                                        border: '1px solid #DD29FF',
                                      },
                                      '& .MuiSwitch-thumb': {
                                        background:
                                          'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                                      },
                                      '&.Mui-checked .MuiSwitch-thumb': {
                                        background:
                                          'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                                      },
                                      '&.Mui-checked .MuiSwitch-track': {
                                        background:
                                          'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)',
                                      },
                                    }}
                                  />
                                }
                                label={row.isBlockListed ? 'Yes' : 'No'}
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <IconButton
                                aria-controls='user-menu'
                                aria-haspopup='true'
                                onClick={event => handleOpenMenu(event, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                sx={{
                                  '& .MuiMenu-paper': {
                                    backgroundColor: 'black'
                                  }
                                }}
                              >
                                <MenuItem onClick={handleView}>
                                  <ListItemIcon>
                                    <VisibilityIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='View' />
                                </MenuItem>
                                {/* <MenuItem onClick={handleEdit}>
                                  <ListItemIcon>
                                    <EditIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='Edit' />
                                </MenuItem> */}
                                {/* <MenuItem onClick={handleDelete}>
                                  <ListItemIcon>
                                    <DeleteIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='Delete' />
                                </MenuItem> */}
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={TABLE_HEAD.length} align="center">
                          No users found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

          </>
        )}

        <Stack
          spacing={2}
          direction='row'
          justifyContent='center'
          mt={2}
          className='pagination-container'
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color='primary'
            showFirstButton
            showLastButton
          />
        </Stack>
      </Card>
    </div>
  )
}

const ViewUser = ({ open, onClose, user }) => {
  // Helper function to render a detail box
  const renderDetailBox = (label, value) => (
    <Box
      border={1}
      borderColor='gray'
      padding={1}
      marginBottom={2}
      borderRadius={1}
    >
      <Typography variant='body1'>
        <strong>{label}:</strong> {value}
      </Typography>
    </Box>
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          background: 'black', // Inner content background
          color: 'white', // Text color
          boxShadow: 'none', // Removes default shadow
          position: 'relative', // Positioning for the pseudo-element
          padding: '16px', // Add padding for inner content
        },
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <DialogTitle style={{ background: 'black', color: 'white' }}>
        User Details
      </DialogTitle>
      <DialogContent style={{ background: 'black', color: 'white' }}>
        {user ? (
          <Box>
            {/* Basic User Information */}
            <Box
              display='flex'
              flexDirection='row'
              flexWrap='wrap'
              justifyContent='space-between'
            >
              <Box width='48%'>
                {renderDetailBox('User ID', user.userId)}
                {renderDetailBox('Username', user.userName)}
                {renderDetailBox('Name', user.firstName + user?.lastName)}
                {renderDetailBox('Premium', user.userPremium ? 'Yes' : 'No')}
                {renderDetailBox('Streak', user.streak + " DAYS")}
                {renderDetailBox('LastActive', moment(user.lastActive).format('MM/DD/YYYY, hh:mm:ss A'))}
              </Box>
              <Box width='48%'>
                {renderDetailBox('League', user.league)}
                {renderDetailBox('Daily TapCount', user.dailyTapCount)}
                {renderDetailBox('CreatedAt', moment(user.createdAt).format('MM/DD/YYYY, hh:mm:ss A'))}
                {renderDetailBox('BlackListed', user.isBlockListed ? 'Yes' : 'No')}
                {renderDetailBox('totalTaskRewards', user.totalTaskRewards)}

              </Box>
            </Box>

            {/* Profile and Geolocation */}
            <Box mt={3}>
              <Typography variant='h6' sx={{ marginBottom: 2 }}>
                Profile & Location
              </Typography>
              <Box
                display='flex'
                flexDirection='row'
                flexWrap='wrap'
                justifyContent='space-between'
              >
                <Box
                  width='48%'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  height='250px' // Adjust the height based on your requirements
                >
                  <img
                    src={user.userProfile || UserImg}
                    alt='User Profile'
                    style={{
                      width: '50%',
                      maxHeight: '300px',
                      objectFit: 'cover',
                      borderRadius: 8
                    }}
                  />
                </Box>
                <Box width='48%'>
                  {renderDetailBox(
                    'Country',
                    user.geolocation.country || 'Not specified'
                  )}
                  {renderDetailBox(
                    'Country Code',
                    user.geolocation.countryCode || 'N/A'
                  )}
                  {renderDetailBox('Locale', user.locale)}
                  {renderDetailBox(
                    'Device OS',
                    user.deviceOS || 'Not specified'
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography>No user details available</Typography>
        )}
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button
          onClick={onClose}
          style={{
            background: 'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)', // Gradient background
            color: 'white', // Text color
            border: 'none', // Remove default border
            borderRadius: '8px', // Optional: Rounded corners
            padding: '8px 16px', // Adjust padding
            cursor: 'pointer', // Change cursor to pointer
            transition: 'background 0.3s', // Smooth transition on hover
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.background =
              'linear-gradient(29.39deg, #6328B5 3.5%, #DD29FF 84.18%)'; // Reverse gradient on hover
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.background =
              'linear-gradient(29.39deg, #DD29FF 3.5%, #6328B5 84.18%)'; // Reset gradient
          }}
        >
          Close
        </Button>

      </DialogActions>
    </Dialog>
  )
}

// const EditUser = ({ open, onClose, user, onSave }) => {
//   const [editedUser, setEditedUser] = useState(user || {})

//   useEffect(() => {
//     setEditedUser(user || {})
//   }, [user])

//   const handleChange = event => {
//     const { name, value, checked } = event.target
//     setEditedUser(prevState => ({
//       ...prevState,
//       [name]: value
//     }))
//   }

//   const handleSave = () => {
//     if (editedUser) {
//       onSave(editedUser)
//     }
//     onClose()
//   }

//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       fullWidth
//       maxWidth='sm'
//       PaperProps={{
//         style: {
//           backgroundColor: 'rgba(0, 0, 0, 0.8)', // Make the modal slightly transparent
//           color: 'white',
//           boxShadow: 'none', // Remove the shadow
//           border: '1px solid gray'
//         }
//       }}
//       BackdropProps={{
//         style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop to make the background more visible
//       }}
//     >
//       <DialogTitle style={{ background: 'black', color: 'white' }}>
//         Edit User
//       </DialogTitle>
//       <DialogContent style={{ background: 'black', color: 'white' }}>
//         {editedUser ? (
//           <div>
//             <TextField
//               label='Username'
//               name='userName'
//               value={editedUser.userName || ''}
//               onChange={handleChange}
//               fullWidth
//               margin='normal'
//             />

//             <TextField
//               label='Points'
//               name='points'
//               type='number'
//               value={editedUser.points || ''}
//               onChange={handleChange}
//               fullWidth
//               margin='normal'
//             />

//             <TextField
//               label='League'
//               name='league'
//               value={editedUser.league || ''}
//               onChange={handleChange}
//               fullWidth
//               margin='normal'
//             />

//             <TextField
//               label='Lifetime Points'
//               name='userLifeTimePoints'
//               type='number'
//               value={editedUser.userLifeTimePoints || ''}
//               onChange={handleChange}
//               fullWidth
//               margin='normal'
//             />
//           </div>
//         ) : (
//           <Typography>No user details available</Typography>
//         )}
//       </DialogContent>
//       <DialogActions style={{ background: 'black' }}>
//         <Button onClick={handleSave} color='primary'>
//           Save
//         </Button>
//         <Button onClick={onClose} color='secondary'>
//           Cancel
//         </Button>
//       </DialogActions>
//     </Dialog>
//   )
// }

// const ConfirmDeleteDialog = ({ open, onClose, onConfirm }) => (
//   <Dialog
//     open={open}
//     onClose={onClose}
//     fullWidth
//     maxWidth='xs' // Keep the dialog compact
//     PaperProps={{
//       style: {
//         backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
//         color: 'white',
//         boxShadow: 'none', // Remove the shadow
//         border: '1px solid gray'
//       }
//     }}
//     BackdropProps={{
//       style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighter backdrop for more visibility of the background
//     }}
//   >
//     <DialogTitle style={{ background: 'black', color: 'white' }}>
//       Confirm Delete
//     </DialogTitle>
//     <DialogContent style={{ background: 'black', color: 'white' }}>
//       <Typography>Are you sure you want to delete this user?</Typography>
//     </DialogContent>
//     <DialogActions style={{ background: 'black' }}>
//       <Button onClick={onClose}>Cancel</Button>
//       <Button onClick={onConfirm} color='error'>
//         Delete
//       </Button>
//     </DialogActions>
//   </Dialog>
// )

const UserManager = () => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [viewOpen, setViewOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)

  const handleView = async userId => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/admin/user-details?userId=${Number(userId)}`
      )
      // console.log('response=========>>', response)

      const userData = response.data.message

      setSelectedUser(userData)
      setViewOpen(true)
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  const handleEdit = user => {
    setSelectedUser(user)
    setEditOpen(true)
  }

  const handleDelete = userId => {
    setSelectedUserId(userId)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setSelectedUserId(null)
  }

  const handleDeleteConfirm = () => {
    if (selectedUserId) {
      backendServices
        .Delete(
          `${process.env.REACT_APP_BASE_URL}/users/deleteUser/${selectedUserId}`
        )
        .then(() => {
          setRefreshTrigger(!refreshTrigger)
          alert('User deleted successfully')
          handleDeleteClose()
        })
        .catch(error => console.error('Error deleting user:', error))
    }
  }

  const handleCloseView = () => {
    setViewOpen(false)
    setSelectedUser(null)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
    setSelectedUser(null)
  }

  const handleSave = updatedUser => {
    backendServices
      .Put(
        `${process.env.REACT_APP_BASE_URL}/admin/blacklist-user/${updatedUser.userId}`,
        updatedUser
      )
      .then(() => {
        setRefreshTrigger(prev => !prev)
        // console.log('User updated successfully')
      })
      .catch(error => console.error('Error updating user:', error))
  }

  const handleToggleBlacklist = async (userId, newStatus) => {
    try {
      await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/admin/blacklist-user/${userId}`,
        { isBlockListed: newStatus }
      )
      setRefreshTrigger(prev => !prev)
      // console.log('User blacklist status updated successfully')
    } catch (error) {
      console.error('Error updating user blacklist status:', error)
    }
  }

  return (
    <Container>
      <Typography variant='h4' gutterBottom>
        Manage Users
      </Typography>
      <UserList
        refreshTrigger={refreshTrigger}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onToggleBlacklist={handleToggleBlacklist}
      />
      <ViewUser open={viewOpen} onClose={handleCloseView} user={selectedUser} />
      {/* <EditUser
        open={editOpen}
        onClose={handleCloseEdit}
        user={selectedUser}
        onSave={handleSave}
      />
      <ConfirmDeleteDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
      /> */}
    </Container>
  )
}

export default UserManager
